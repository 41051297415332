<template>
  <router-view></router-view>
</template>

<script>
export default {}
</script>

<style lang="less">
html,
body,
#app {
  width: 100%;
  height: 100%;
  font-size: 14px;
}
div {
  box-sizing: border-box;
}
img {
  -webkit-user-drag: none;
}
.el-popper {
  min-width: 100px !important;
}
.el-dialog {
  min-width: 666px !important;
}
</style>
