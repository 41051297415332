import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import ElementPlus from 'element-plus'
// 将饿了么ui设置成中文
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import 'element-plus/lib/theme-chalk/index.css'
import './assets/css/common.css'
import Paging from './components/paging.vue'
import Preservation from '@/components/preservation.vue'
import No from '@/components/no.vue'

String.prototype.splice = function (start, newStr) {
  return this.slice(0, start) + newStr + this.slice(start)
}
// console.warn = function () {}
createApp(App)
  .component('Paging', Paging)
  .component('Preservation', Preservation)
  .component('No', No)
  .use(store)
  .use(router)
  .use(ElementPlus, { locale })
  .mount('#app')
