//  菜单图片地址规则 menuImgUrl+item.name+'.png'
export const addrouter = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    redirect: '/instantSession',
    children: [
      {
        path: '/instantSession',
        name: 'instantSession',
        component: () => import('../views/instantSession/index.vue'),
        meta: {
          name: '即时会话',
          hierarchy: 1
        }
      },
      // {
      //   path: '/customerCenter',
      //   name: 'customerCenter',
      //   component: () => import('../views/subHome.vue'),
      //   redirect: '/customerCenter/allUser',
      //   meta: {
      //     hierarchy: 1,
      //     name: '客户中心'
      //   },
      //   children: [
      //     {
      //       path: '/customerCenter/allUser',
      //       name: 'allUser',
      //       component: () => import('../views/customerCenter/allUser.vue'),
      //       meta: {
      //         name: '全部客户',
      //         hierarchy: 2
      //       }
      //     }
      //   ]
      // },
      // {
      //   path: '/historicalConversation',
      //   name: 'historicalConversation',
      //   component: () => import('../views/subHome.vue'),
      //   meta: {
      //     name: '历史会话',
      //     hierarchy: 1
      //   },
      //   redirect: '/customerCenter/ManualCustomer',
      //   children: [
      //     {
      //       path: '/customerCenter/ManualCustomer',
      //       name: 'ManualCustomer',
      //       component: () => import('../views/historicalConversation/ManualCustomer.vue'),
      //       meta: {
      //         name: '人工客服',
      //         hierarchy: 2
      //       }
      //     }
      //   ]
      // },
      // {
      //     path: '/serviceManagement',
      //     name: 'serviceManagement',
      //     component: () => import('../views/serviceManagement/index.vue'),
      //     meta: {
      //         name: '客服管理',
      //         hierarchy: 1
      //     }
      // },
      {
        path: '/autoReply',
        name: 'autoReply',
        component: () => import('../views/subHome.vue'),
        meta: {
          hierarchy: 1,
          name: '自动回复'
        },
        redirect: '/autoReply/serviceReply',
        children: [
          {
            path: '/autoReply/serviceReply',
            name: 'serviceReply',
            component: () => import('../views/autoReply/serviceReply.vue'),
            meta: {
              name: '客服自动回复',
              hierarchy: 2
            },
            redirect: '/autoReply/serviceReply/welcome',
            children: [
              {
                path: '/autoReply/serviceReply/welcome',
                name: 'welcome',
                component: () => import('../views/autoReply/sonMenu/welcome.vue'),
                meta: {
                  name: '客服首句欢迎语',
                  hierarchy: 3
                }
              },
              {
                path: '/autoReply/serviceReply/waiting',
                name: 'waiting',
                component: () => import('../views/autoReply/sonMenu/waiting.vue'),
                meta: {
                  name: '客服排队中提醒',
                  hierarchy: 3
                }
              },
              {
                path: '/autoReply/serviceReply/off_work',
                name: 'off_work',
                component: () => import('../views/autoReply/sonMenu/off_work.vue'),
                meta: {
                  name: '客服下班后提醒',
                  hierarchy: 3
                }
              },
              {
                path: '/autoReply/serviceReply/faq',
                name: 'faq',
                component: () => import('../views/autoReply/sonMenu/faq.vue'),
                meta: {
                  name: '客服常见问题',
                  hierarchy: 3
                }
              }
            ]
          }
        ]
      },
      {
        path: '/systemSettings',
        name: 'systemSettings',
        component: () => import('../views/subHome.vue'),
        meta: {
          name: '系统设置',
          hierarchy: 1
        },
        redirect: '/systemSettings/SelfMenu',
        children: [
          // {
          //   path: '/systemSettings/QuickReply',
          //   name: 'QuickReply',
          //   component: () => import('../views/systemSettings/QuickReply.vue'),
          //   meta: {
          //     name: '快捷回复',
          //     hierarchy: 2
          //   }
          // },
          // {
          //   path: '/systemSettings/Intelligent',
          //   name: 'Intelligent',
          //   component: () => import('../views/systemSettings/Intelligent.vue'),
          //   meta: {
          //     name: '智慧问答库',
          //     hierarchy: 2
          //   }
          // },
          // {
          //   path: '/systemSettings/MessageRecall',
          //   name: 'MessageRecall',
          //   component: () => import('../views/systemSettings/MessageRecall.vue'),
          //   meta: {
          //     name: '消息撤回设置',
          //     hierarchy: 2
          //   }
          // },
          // {
          //   path: '/systemSettings/SessionTimeout',
          //   name: 'SessionTimeout',
          //   component: () => import('../views/systemSettings/SessionTimeout.vue'),
          //   meta: {
          //     name: '会话超时设置',
          //     hierarchy: 2
          //   }
          // },
          {
            path: '/systemSettings/SelfMenu',
            name: 'SelfMenu',
            component: () => import('../views/systemSettings/SelfMenu.vue'),
            meta: {
              name: '自助菜单',
              hierarchy: 2,
              noLogo: 1
            }
          }
          // {
          //   path: '/systemSettings/evaluation',
          //   name: 'evaluation',
          //   component: () => import('../views/systemSettings/evaluation.vue'),
          //   meta: {
          //     name: '满意度评价',
          //     hierarchy: 2
          //   }
          // },
        ]
      }
    ]
  }
]
