import { createRouter, createWebHashHistory } from 'vue-router'
import { addrouter } from './addrouter.js'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 添加动态路由
const dealRoute = (addItem, parentName) => {
  if (!parentName) {
    addItem.map((item) => {
      router.addRoute(item)
      if (item.children) {
        dealRoute(item.children, item.name)
      }
    })
  } else {
    addItem.map((item) => {
      router.addRoute(parentName, item)
      if (item.children) {
        dealRoute(item.children, item.name)
      }
    })
  }
}

dealRoute(addrouter)

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token')
  if (to.path == '/login') {
    next()
  } else {
    if (!token) {
      next('/login')
    } else {
      next()
    }
  }
})

export default router
