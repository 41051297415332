<template>
  <div class="preservationBox">
    <div class="custBtnPri" @click="preservationFn">保存</div>
  </div>
</template>

<script>
export default {
  setup(props, context) {
    const preservationFn = () => {
      context.emit('preservation')
    }
    return {
      preservationFn
    }
  }
}
</script>

<style lang="less" scoped>
.preservationBox {
  position: fixed;
  left: 238px;
  right: 0;
  padding: 10px 0;
  bottom: 0;
  z-index: 1;
  box-shadow: 0 -3px 5px #eee;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
