<template>
  <div
    class="pagBox"
    :style="{ 'justify-content': $slots.allCheck ? 'space-between' : 'flex-end' }"
  >
    <slot name="allCheck"></slot>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="row"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  props: {
    total: {
      type: Number,
      default: 100
    }
  },
  setup(props, content) {
    let page = ref(1)
    let row = ref(10)
    const handleSizeChange = (val) => {
      row.value = val
      console.log(`每页 ${val} 条`)
      content.emit('againGetInfo', { row: val })
    }
    const handleCurrentChange = (val) => {
      page.value = val
      console.log(`当前页: ${val}`)
      content.emit('againGetInfo', { page: val })
    }
    return { handleSizeChange, handleCurrentChange, page, row }
  }
}
</script>

<style lang="less" scoped>
.pagBox {
  height: 47px;
  background: #f7f8fa;
  display: flex;
  align-items: center;
  padding: 0 25px;
}
</style>
